import axios from 'axios';
import debug from 'debug';
import { getTimezone } from 'src/app/obx/pages/schedules/helper';
import { LOGOUT } from 'src/app/router/constant/ROUTE';
import history from 'src/app/router/utils/history';
import { getKeyFromUrlQueryParam } from 'src/helper/utilityFunctions';
import { franchiseIdUrlQueryParam, franchiseTimeZone, rolesEnum } from 'src/utils/constants';

import store from '../../redux/store/index';

// Create separate debug function for separate purposes of debugging
const debugHttpRequestError = debug('signel:error:http:request');
const debugHttpResponse = debug('signel:http:response');
const debugHttpResponseError = debug('signel:error:http:response');

const getFranchiseId = (authState) => {
  try {
    /**
     * check if the URL path is HO and query params contains the key franchiseId
     * then return the key extracted from URL, else return the key from auth redux
     * */
    if (
      authState?.userRole?.slug === rolesEnum.homeOfficer &&
      getKeyFromUrlQueryParam(franchiseIdUrlQueryParam)
    ) {
      return getKeyFromUrlQueryParam(franchiseIdUrlQueryParam) || null;
    }
    return authState.franchiseId || null;
  } catch (e) {
    return authState.franchiseId || null;
  }
};

const getFranchiseTimeZone = (franchiseTimeZone) => {
  try {
    /**
     * check if the URL path is HO and query params contains the key franchiseId
     * then return the key extracted from URL, else return the key from auth redux
     * */
    return getKeyFromUrlQueryParam(franchiseTimeZone) || null;
  } catch (e) {
    return null;
  }
};

const instance = axios.create({
  baseURL: '',
});

// Add a request interceptor on this `instance` of `axios`
instance.interceptors.request.use(
  function (config) {
    // Do something before the request is sent

    // Get access token from Redux store and add it to request headers
    const appState = store.getState();

    const accessToken = appState.auth.accessToken || null;
    const franchiseId = getFranchiseId(appState.auth) || null;
    const TimeZone =
      appState.auth?.franchiseTimeZone || getFranchiseTimeZone(franchiseTimeZone) || getTimezone();
    const currentLanguage = appState?.auth?.currentLanguage?.code || 'en';

    const defaultHeaders = {
      Accept: 'application/json',
      'Cache-Control': 'public, max-age=86400',
    };

    if (!config?.skipAuth) {
      if (accessToken !== null) {
        defaultHeaders.Authorization = `Bearer ${accessToken}`;
        defaultHeaders.franchise_id = `${franchiseId}`;
        defaultHeaders.currentLanguage = `${currentLanguage}`;
        defaultHeaders.timezone = `${TimeZone}`;
      }
    }

    config.headers = {
      ...defaultHeaders,
      ...config.headers,
    };

    // Return the modified request config object for proceeding
    return config;
  },
  function (error) {
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      debugHttpRequestError('%o', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      debugHttpRequestError('Error %s', error.message);
    }

    // log a detailed information about the HTTP error
    debugHttpRequestError(error.toJSON());

    // Return the error object for next Promise chain
    return Promise.reject(error);
  },
);

// Add a response interceptor on this `instance` of `axios`
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    // Log the HTTP response on console
    debugHttpResponse(`${response.config.method} ${response.config.url} %o`, response.data);

    return response?.data ? response?.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (axios.isCancel(error)) {
      debugHttpResponseError(error.message);
    } else {
      if (error.response) {
        // Log the HTTP error info
        debugHttpResponseError(
          `${error.config.method} ${error.config.url} %o`,
          error.response.data,
        );

        // Clear the Redux store if response code is 401 (Unauthenticated)
        if (error.response.status === 401) {
          // LogoutRedux();
          history.push(LOGOUT);
        }
      }

      // log a detailed information about the HTTP error
      debugHttpResponseError(error.toJSON());
    }

    // Return the error object for next Promise chain
    return Promise.reject(error);
  },
);

export default instance;

const palette = {
  ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////
  //////////// New Updated Colors

  // TEXT COLORS
  textPrimary: '#262527',
  textSecondary1: '#444446',
  textSecondary2: '#5B5B5F',
  textSecondary3: '#86868B',
  textPlaceholder: '#6A6A70',
  textPlaceholderField: '#cccccc',
  textDisabled: '#AEAEB2',
  textOnColor: '#ffffff',
  textBrand: '#146dff',
  textBrandHover: '#0059FF',
  textBrandDisabled: '#A9DEFF',
  textAlert: '#b32318',
  textAlerDisabled: '#FECDCA',
  textSuccess: '#2e964b',
  textWarning: '#f19f02',

  // BORDER COLORS
  borderSubtle1: '#e6e6e7',
  borderSubtle2: '#d0cfd2',
  borderStrong1: '#AEAEB2',
  borderStrong2: '#6A6A70',
  borderBrand: '#146DFF',
  borderWarning: '#F19F02',
  borderSuccess: '#2E964B',
  borderAlert: '#E43F32',
  borderPurple: '#9747FF',
  borderAlertHover: '#B32318',
  borderAlertDisabled: '#FECDCA',
  borderBrandDisabled: '#A9DEFF',

  // SURFACE / BACKGROUND COLORS
  surfaceWhite: '#ffffff',
  surfaceGreySubtle: '#F5F5F6',
  surfaceGreyLight: '#f6f6f6',
  surfaceGreyStrong1: '#6A6A70',
  surfaceGreyDisabled: '#AEAEB2',
  surfaceGreyStrong2: '#262527',
  surfaceAlertSubtle: '#FBEEED',
  surfaceAlertStrong: '#E43F32',
  surfaceAlertDisabled: '#FECDCA',
  surfaceAlertHover: '#B32318',
  surfaceSuccessSubtle: '#EFF8EF',
  surfaceSuccessStrong: '#31A150',
  surfaceWarningSubtle: '#FEF0C7',
  surfaceWarningStrong: '#FFAC0D',
  surfaceBrandSubtle: '#E5F6FF',
  surfaceBrand: '#146DFF',
  surfaceBrandDisabled: '#A9DEFF',
  surfaceBrandHover: '#0059FF',
};

export default palette;
